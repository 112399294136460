import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  Stack,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import textureImage from "../../assets/images/lines_texture.png";
import mediaImage from "../../assets/images/icons/media.png";
import softwareImage from "../../assets/images/icons/software.png";
import webdesignImage from "../../assets/images/icons/webdesign.png";
import "../../css/rotate.css";
import Techstack from "../Techstack";
import personImage from "../../assets/images/person.png";
import forfunImage from "../../assets/images/forfun.jpg";
import ParallaxMouse from "../ParallaxMouse";
import CustomIcon from "../CustomIcon";
import { generateLines } from "../../utils/generateLinens";
import { LineType } from "../../types/LineType";
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const lineSet = generateLines(10);

const About = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [beingHovered, setBeingHovered] = useState<boolean>(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <Box transform="translate3d(0, 0, 0)">
      <Box height={32} display={"block"}></Box>
      <Flex
        p={32}
        minHeight={"70vh"}
        alignItems={"center"}
        justifyContent={"center"}
        position={"relative"}
        backgroundImage={textureImage}
        backgroundSize={"150% 150%"}
        backgroundPosition={"center"}
      >
        {lineSet.map((line: LineType) => {
          return (
            <Box
              key={line.x}
              {...(line.x === "right" ? { right: 0 } : { left: 0 })}
              position={"absolute"}
              top={line.y}
              pointerEvents={"none"}
              zIndex={3}
              opacity={0.01 * getRandomInt(20, 50)}
              transition="1s all cubic-bezier(0, 0.55, 0.45, 1)"
              width={isLoaded ? (beingHovered ? 0 : line.w) : 0}
            >
              <ParallaxMouse sensitivity={0.01 * getRandomInt(1, 20)}>
                <Box
                  background="rgba(255,255,255,0.1)"
                  width="100%"
                  height={8}
                  border={"1px solid white"}
                ></Box>
              </ParallaxMouse>
            </Box>
          );
        })}

        <Heading
          fontSize={{ base: "80px", md: "140px", lg: "200px" }}
          color={"white"}
          onMouseOver={() => setBeingHovered(true)}
          onMouseLeave={() => setBeingHovered(false)}
          zIndex={4}
          pos={"relative"}
          textTransform={"uppercase"}
        >
          <ParallaxMouse sensitivity={0.015}>About</ParallaxMouse>
        </Heading>
        <Box
          sx={{
            position: "absolute",
            top: "100px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 5,
          }}
        >
          <ParallaxMouse sensitivity={0.03}>
            <Image
              src={personImage}
              sx={{
                maxWidth: { base: "88vw", md: "100%" },
                width: { lg: "100%" },
                maxHeight: { lg: "800px" },
              }}
            />
          </ParallaxMouse>
        </Box>
      </Flex>
      <Box
        paddingBottom={{ base: "200px", lg: "400px" }}
        paddingTop={{ base: "200px", lg: "600px" }}
      >
        <Box
          position={"relative"}
          sx={{
            "&:hover .css-text": {
              filter: "blur(20px)",
            },
          }}
        >
          <Box
            className={"css-text"}
            width={"100%"}
            maxWidth={{ base: "94%", md: "60%", lg: "1100px" }}
            paddingX={{ base: 12, md: "0" }}
            fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
            fontWeight={"bold"}
            lineHeight={"1.2"}
            transition={"0.3s ease all"}
            filter="blur(0px)"
            position={"absolute"}
            top="50%"
            left="50%"
            transform="translate(-40%,-40%)"
            backgroundImage={textureImage}
            backgroundSize={"cover"}
            textShadow={"0 0 10px rgba(255,255,255,0.1)"}
            bgClip={"text"}
            zIndex={1}
            opacity={0.5}
            pointerEvents={"none"}
            userSelect={"none"}
          >
            <ParallaxMouse>
              <Text>
                I'm Victor Fuentes, a front-end software engineer who is
                passionate about creating intuitive and engaging user
                experiences. I specialize in building responsive web
                applications with technologies like React Javascript , & CSS.
              </Text>
              <br />
              <Text>
                I've also designed everything from websites and interfaces, to
                brochures, book covers and motion graphics.
              </Text>
            </ParallaxMouse>
          </Box>
          <Box
            maxWidth={{ base: "94%", md: "60%", lg: "1100px" }}
            margin={{ base: 12, md: "0 auto" }}
            fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
            fontWeight={"bold"}
            lineHeight={"1.2"}
            pos={"relative"}
            zIndex={2}
          >
            <Text>
              I'm Victor Fuentes, a front-end software engineer who is
              passionate about creating intuitive and engaging user experiences.
              I specialize in building responsive web applications with
              technologies like <span style={{ color: "#1cceff" }}>React</span>,{" "}
              <span style={{ color: "#ffe138" }}>Javascript</span>, &{" "}
              <span style={{ color: "#ff911c" }}>CSS</span>.
            </Text>
            <br />
            <Text>
              I've also designed everything from websites and interfaces, to
              brochures, book covers and motion graphics.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box paddingY={0} pos={"relative"}>
        <Flex p={12} gap={6} flexDir={{ base: "column", lg: "row" }}>
          <Box
            flex={1}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              width={"100%"}
              background={"brand.trans"}
              bgGradient={"linear(to-br, brand.trans, black)"}
              border={"1px solid white"}
              padding={{ base: 6, lg: 16 }}
            >
              <Stack spacing={6}>
                <HStack spacing={8}>
                  <Box>
                    <CustomIcon type="code" size={16} />
                  </Box>
                  <Box>
                    <Heading>11+ Years Experience</Heading>
                  </Box>
                </HStack>{" "}
                <Box
                  width={"4px"}
                  height={{ base: 10, md: 20 }}
                  background="white"
                ></Box>
                <Text fontSize={{ base: "md", md: "xl" }}>
                  With a decade of experience as both a software engineer and
                  designer, I bring a wealth of expertise to every project. From
                  the boring to exciting and everything between, I've probably
                  ran into it.
                </Text>
              </Stack>
            </Box>
          </Box>
          <Box
            flex={1}
            gap={{ base: 30, lg: 60 }}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            flexDir={"column"}
          >
            <Box
              width={"100%"}
              background={"brand.trans"}
              bgGradient={"linear(to-br, brand.trans, black)"}
              border={"1px solid white"}
              padding={{ base: 6, lg: 16 }}
            >
              <Stack spacing={6}>
                <HStack spacing={8}>
                  <Box>
                    <CustomIcon type="lab" size={16} />
                  </Box>
                  <Box>
                    <Heading>Multi-Disciplined</Heading>
                  </Box>
                </HStack>
                <Box
                  width={"4px"}
                  height={{ base: 10, md: 20 }}
                  background="white"
                ></Box>{" "}
                <Text fontSize={{ base: "md", md: "xl" }}>
                  With an understanding from concept to deliver, I use my
                  cross-skill approach to bridge disciplines and unlock
                  innovative solutions.
                </Text>
              </Stack>
            </Box>
            <Box
              width={"100%"}
              background={"brand.trans"}
              bgGradient={"linear(to-br, brand.trans, black)"}
              border={"1px solid white"}
              padding={{ base: 6, lg: 16 }}
            >
              <Stack spacing={6}>
                <HStack spacing={8}>
                  <Box>
                    <CustomIcon type="working" size={16} />
                  </Box>
                  <Box>
                    <Heading>Learning Oriented</Heading>
                  </Box>
                </HStack>
                <Box
                  width={"4px"}
                  height={{ base: 10, md: 20 }}
                  background="white"
                ></Box>{" "}
                <Text fontSize={{ base: "md", md: "xl" }}>
                  As a self-taught professional, I'm quick to learn and eager to
                  expand my skills. My forever-learner mindset has set me up for
                  continuous growth and adaptability.
                </Text>
              </Stack>
            </Box>
          </Box>
          <Box
            flex={1}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              width={"100%"}
              background={"brand.trans"}
              bgGradient={"linear(to-br, brand.trans, black)"}
              border={"1px solid white"}
              padding={{ base: 6, lg: 16 }}
            >
              <Stack spacing={6}>
                <HStack spacing={8}>
                  <Box>
                    <CustomIcon type="solutions" size={16} />
                  </Box>
                  <Box>
                    <Heading>Solution Driven</Heading>
                  </Box>
                </HStack>
                <Box
                  width={"4px"}
                  height={{ base: 10, md: 20 }}
                  background="white"
                ></Box>{" "}
                <Text fontSize={{ base: "md", md: "xl" }}>
                  With a passion for solving problems I pour myself into my
                  work. Whether in a individual setting or a team setting, I'm
                  always striving to find the best way to deliver value,
                  contribute meaningfully while promoting a positive work
                  culture.
                </Text>
              </Stack>
            </Box>
          </Box>
        </Flex>
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%,-50%)"}
        >
          <ParallaxMouse>
            <Box
              height="300px"
              width="300px"
              background={"brand.main"}
              filter={"blur(100px)"}
              opacity={0.25}
              pointerEvents={"none"}
              zIndex={5}
            ></Box>
          </ParallaxMouse>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"} paddingY={"300px"}>
        <Techstack />
      </Box>
      <Box>
        <Flex fontWeight={"bold"} display={{ base: "block", lg: "flex" }}>
          <Flex
            flex={1}
            height={"100%"}
            backgroundColor={"brand.main"}
            alignItems={"center"}
            justifyContent={"center"}
            minHeight={"800px"}
            overflow={"hidden"}
          >
            <Stack gap={0} textAlign={"center"}>
              <Box fontSize={"6xl"} lineHeight={1} color="black">
                Over
              </Box>
              <Box
                fontSize={"220px"}
                lineHeight={1}
                marginBottom={"-24px"}
                sx={{
                  "& .css_ten_1": {
                    display: "inline-block",
                    transformOrigin: "center",
                    transition: "0.3s ease all",
                    transform: "rotate(0) translateY(0) scale(1)",
                  },
                  "& .css_ten_2": {
                    display: "inline-block",
                    transition: "0.3s ease all",
                    transform: "translateX(0px) scale(1)",
                  },
                  "&:hover": {
                    "& .css_ten_1": {
                      transform: "rotate(90deg) translateY(80px) scale(5)",
                      opacity: "0.5",
                    },
                    "& .css_ten_2": {
                      transform: "translateX(50px) scale(5)",

                      opacity: "0.5",
                    },
                  },
                }}
              >
                <span className="css_ten_1">1</span>
                <span className="css_ten_2">1</span>
              </Box>
              <Box fontSize={"6xl"} lineHeight={1} color="black">
                Years
              </Box>
              <Box fontSize={{ base: "3xl" }} lineHeight={1} color="black">
                Experience
              </Box>
            </Stack>
          </Flex>
          <Flex
            flex={1}
            height={"100%"}
            backgroundColor={"white"}
            color={"brand.main"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            minHeight={"800px"}
            flexDir={"column"}
            gap={2}
          >
            <Text fontSize={"md"} color={"black"} mb={2}>
              Skills & Tools:
            </Text>
            <Stack
              spacing={0}
              textAlign={"left"}
              fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
            >
              <Box>Javascript</Box>
              <Box>UI Engineering</Box>
              <Box>PHP</Box>
              <Box>HTML/CSS/LESS/Sass</Box>
              <Box>Node + React + AngularJS</Box>
              <Box>Adobe CS</Box>
              <Box>Wordpress + Shopify</Box>
              <Box>Deployment & Maint.</Box>
              <Box>Solutions Architecting</Box>
              <Box>Graphic & Motion Design</Box>
            </Stack>
          </Flex>
        </Flex>
      </Box>
      <Box
        width={"100%"}
        height={"200px"}
        backgroundImage={textureImage}
        backgroundPosition={"center center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        borderBottom={"1px solid white"}
      ></Box>
      <Box
        borderTop={"1px solid white"}
        borderBottom={"1px solid white"}
        paddingY={"200px"}
        paddingX={12}
        pos={"relative"}
        background={"brand.trans"}
        bgGradient={"linear(to-br, brand.trans, black)"}
      >
        <Box
          position={"absolute"}
          top="0"
          left="0"
          bottom="0"
          right="0"
          zIndex={1}
          opacity={1}
        ></Box>

        <Flex
          gap={12}
          zIndex={2}
          position={"relative"}
          flexDir={{ base: "column", lg: "row" }}
        >
          {[
            { title: "Software Engineering", image: softwareImage },
            { title: "UI/Web Design", image: webdesignImage },
            { title: "Visual Design", image: mediaImage },
          ].map((item) => {
            return (
              <Box
                flex={1}
                paddingX={8}
                paddingY={{ base: 8, md: 32 }}
                border={"1px solid white"}
                backgroundColor={"blackAlpha.200"}
                textAlign={"center"}
              >
                <Heading mb={8} fontSize={{ base: "xl", lg: "3xl" }}>
                  {item.title}
                </Heading>

                <Image
                  src={item.image}
                  alt={""}
                  maxWidth={{ base: "100%", lg: "100%" }}
                  width={{ base: "260px", lg: "340px" }}
                  style={{ margin: "0 auto" }}
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
      <Box
        width={"100%"}
        height={"200px"}
        backgroundImage={textureImage}
        backgroundPosition={"center center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        borderBottom={"1px solid white"}
      ></Box>

      <Box pos={"relative"}>
        <Box
          position={"absolute"}
          bottom={"0px"}
          left={"0px"}
          height="600px"
          width="600px"
          background={"brand.main"}
          filter={"blur(100px)"}
          opacity={0.5}
          pointerEvents={"none"}
          zIndex={0}
        ></Box>
        <Flex
          alignItems={"center"}
          p={12}
          marginY={{ base: "200px", lg: "300px" }}
          display={{ base: "block", lg: "flex" }}
          zIndex={1}
        >
          <Box flex={2} textAlign={"center"}>
            <Box
              width={{ base: "100%", lg: "80%" }}
              height={{ base: "400px", lg: "800px" }}
              bgColor={"brand.main"}
              backgroundImage={forfunImage}
              backgroundSize={"cover"}
              backgroundPosition={"center"}
              boxShadow={"12px 12px 0 white"}
              margin="0 auto"
              position={"relative"}
            >
              <Box pos={"absolute"} left={-10} bottom={-6}>
                <ParallaxMouse sensitivity={0.01}>
                  <Box
                    className="rotating-element"
                    w={"200px"}
                    h={"200px"}
                    transform={"rotate(-10deg)"}
                    backgroundImage={textureImage}
                    backgroundPosition={"center center"}
                    backgroundRepeat={"no-repeat"}
                    backgroundSize={"500% auto"}
                    fontSize={"5xl"}
                    borderRadius={"full"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text lineHeight={1.15}>
                      Hello
                      <br />
                      Y'all
                    </Text>
                  </Box>
                </ParallaxMouse>
              </Box>
            </Box>
          </Box>
          <Box flex={1}>
            <Text
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              paddingY={{ base: 12 }}
            >
              When not making things on computers you can find me out playing
              songs I wrote 🎸, sipping coffee ☕, on an adventure with my dog
              🐕, on a road trip 🚗, or searching for the best pizza 🍕
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        width={"80%"}
        ml={"10%"}
        height={40}
        marginTop={-20}
        marginBottom={-20}
        borderRadius={20}
        overflow={"hidden"}
        backgroundImage={textureImage}
        backgroundPosition={"center center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"120% auto"}
        zIndex={5}
        position={"relative"}
      ></Box>
      <Box>
        <Flex
          p={32}
          minHeight={"900px"}
          bgColor={"brand.main"}
          alignItems={"center"}
          justifyContent={"center"}
          color="white"
          // display={{ md: "flex" }}
          minWidth={"100%"}
        >
          <Stack
            textAlign={"center"}
            spacing={16}
            minWidth={"100vw"}
            pos={"relative"}
          >
            <Text
              fontSize={{ base: "4xl", md: "5xl", lg: "7xl" }}
              fontWeight={"bold"}
              lineHeight={1.2}
              width="100%"
            >
              Interested in learning more?
            </Text>

            <Flex
              gap={8}
              flexDir={{ base: "column", lg: "row" }}
              alignItems={"center"}
              maxW={"900px"}
              w={"100%"}
              margin="0 auto"
            >
              <Box flex={1}>
                <Button
                  minW={"100%"}
                  as={Link}
                  to="/projects"
                  variant={"branded"}
                >
                  See Recent Projects
                </Button>
              </Box>
              <Box flex={1}>
                <Button minW={"100%"} as={Link} to="/about" variant={"branded"}>
                  About Me
                </Button>
              </Box>
            </Flex>
          </Stack>
        </Flex>
      </Box>

      <Box
        position={"absolute"}
        top={"400px"}
        right={"-100px"}
        pointerEvents={"none"}
        zIndex={5}
      >
        <Box
          height="600px"
          width="600px"
          background={"brand.main"}
          filter={"blur(100px)"}
          opacity={0.5}
        ></Box>
      </Box>
    </Box>
  );
};

export default About;
