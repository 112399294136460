import { Box, Button, Text, Heading, Flex, Stack } from "@chakra-ui/react";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

//TODO: clean up
//TODO: music info
//TODO: real links confirm

import treeImage01 from "../../assets/images/tree/logo-1.png";
import treeImage02 from "../../assets/images/tree/logo-2.png";
import treeImage04 from "../../assets/images/tree/logo-4.png";
import treeImage05 from "../../assets/images/tree/logo-5.png";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Career",
    text: "My career as a Software Engineer and Designer spans multiple companies, across multiple business sectors.",
    buttonText: "Hire Me for Your Team",
    bgColor: "#0d2cc9",
    logo: treeImage02,
    link: "https://victorfuentes.io",
  },
  {
    title: "Freelance",
    text: "Namless Studio produces high end digital design and development.",
    buttonText: "Now Booking for 2025",
    bgColor: "#1a9dff",
    logo: treeImage05,
    link: "https://thisisnameless.com",
  },
  {
    title: "Music",
    text: "I've been writing and performing music for over 20 years..",
    buttonText: "Listen to My Latest Release",
    bgColor: "red",
    logo: "",
    link: "https://spotify.com",
  },
  {
    title: "Personal Brand",
    text: "'Just Victor' is the brand I use to post fun, helpful, personal things.",
    buttonText: "Join My Adventure",
    bgColor: "#1d2028",
    logo: treeImage01,
    link: "https://victorfuentes.io",
  },
  {
    title: "Creative",
    text: "Ftairs is the digital moniker I've used for years. It's all creative, all the time.",
    buttonText: "Explore My Creative World",
    bgColor: "#000016",
    logo: treeImage04,
    link: "https://youtube.com",
  },
];

// TODO: add item type
const TreeItem = ({
  item,
  setCurrentHover,
  currentHover,
}: {
  item: {
    title: string;
    text: string;
    buttonText: string;
    bgColor: string;
    logo: string;
    link: string;
  };
  setCurrentHover: Dispatch<SetStateAction<string>>;
  currentHover: string;
}) => {
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [targetHeight, setTargetHeight] = useState<number | null>(null);

  const onMouseEnterHandler = () => {
    setCurrentHover(item.title);
  };

  const onMouseLeaveHandler = () => {
    setCurrentHover("");
  };

  // Update target height when innerRef changes
  useLayoutEffect(() => {
    if (innerRef.current) {
      setTargetHeight(innerRef.current.clientHeight);
    }
  }, []);

  return (
    <Box
      flex={1}
      bg={item.bgColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      transition="0.3s ease all"
      color="white"
      position="relative"
      fontFamily={"sans-serif"}
      sx={{
        "& *": {
          fontFamily: "sans-serif",
        },
      }}
    >
      <Box
        width="20px"
        height="20px"
        position="absolute"
        top={item.title === currentHover ? "100px" : "-10px"}
        left="50%"
        marginLeft="-10px"
        borderRadius="20px"
        border="4px solid white"
        zIndex={2}
        opacity={item.title === currentHover ? "1" : "0.1"}
        transition="0.3s ease all"
      ></Box>

      {/* <Box
        className="css_treecardbg"
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        backgroundImage={`url(https://picsum.photos/200/30${4})`}
        backgroundSize="cover"
        backgroundPosition="center"
        zIndex={1}
        opacity={0.33}
        transition="0.3s ease all"
      ></Box> */}

      <Stack
        className="css_treecardtext"
        position="relative"
        zIndex={2}
        textAlign="center"
        padding={8}
        paddingY="200px"
        spacing={8}
        transition="0.3s ease all"
      >
        <Box
          w={"100%"}
          h={14}
          backgroundImage={item.logo}
          backgroundRepeat={"no-repeat"}
          bgPosition={"center"}
          backgroundSize={"contain"}
          mb={4}
        ></Box>

        <Box
          sx={{
            height: item.title === currentHover ? `${targetHeight}px` : "0px",
            // overflow: "hidden",
            transition: "0.3s ease all",
          }}
        >
          {/* Content that expands based on height */}
          <Box
            ref={innerRef}
            transform={
              item.title === currentHover
                ? "translateY(0px)"
                : "translateY(50px)"
            }
            opacity={item.title === currentHover ? "1" : "0"}
            transition="0.3s ease all"
          >
            <Heading mb={8}>{item.title}</Heading>
            <Text mb={8}>{item.text}</Text>
            <Button
              as={Link}
              to={item.link}
              background={"whiteAlpha.400"}
              color={"black"}
            >
              {item.buttonText}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const Tree = () => {
  const [currentHover, setCurrentHover] = useState("");
  return (
    <Box>
      <Flex w={"100vw"} h="100vh" display={{ base: "block", "2xl": "flex" }}>
        {data.map((item, index) => {
          return (
            <TreeItem
              item={item}
              setCurrentHover={setCurrentHover}
              currentHover={currentHover}
            ></TreeItem>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Tree;
