import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Projects from "./components/pages/Projects";
import Contact from "./components/pages/Contact";
import Page from "./components/Page";
import SingleProject from "./components/pages/SingleProject";
import ScrollToTop from "./components/ScrollTop";
import Tree from "./components/pages/Tree";
import "./css/fonts/main.css";
import { AnimatePresence } from "framer-motion";
import { Box } from "@chakra-ui/react";
import Header from "./components/Header";
import grainImage from "./assets/images/grain.jpg";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-D9P73VKKYX");
  }, []);
  return (
    <>
      <ScrollToTop />
      <Box
        aria-hidden
        w="100%"
        h="100%"
        pointerEvents={"none"}
        position={"fixed"}
        top="0"
        left={0}
        zIndex={10}
        backgroundImage={grainImage}
        opacity={0.015}
      ></Box>
      <Box>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.key}>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Page
                    title="Victor Fuentes"
                    showFooter={false}
                    desc="Welcome to my portfolio"
                  >
                    <Home />
                  </Page>
                </>
              }
            />
            <Route
              path="about"
              element={
                <>
                  <Header />
                  <Page
                    title="About - Victor Fuentes"
                    desc="Find out more about my skills and history"
                  >
                    <About />
                  </Page>
                </>
              }
            />
            <Route
              path="projects"
              element={
                <>
                  <Header />{" "}
                  <Page title="Projects - Victor Fuentes">
                    <Projects />
                  </Page>
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Header />{" "}
                  <Page
                    title="Contact - Victor Fuentes"
                    desc="Contact me about roles and opportunities"
                  >
                    <Contact />
                  </Page>
                </>
              }
            />
            <Route
              path="/project/:projectId"
              element={
                <>
                  <Header />{" "}
                  <Page
                    title="Victor Fuentes"
                    desc="A Recent Project I've Done"
                  >
                    <SingleProject />
                  </Page>
                </>
              }
            />
            <Route path="/tree" element={<Tree />} />
            <Route
              path="*"
              element={
                <>
                  <Navigate to="/" />
                </>
              }
            />
          </Routes>
        </AnimatePresence>
      </Box>
    </>
  );
}

export default App;
