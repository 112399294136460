import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const TextSwap = ({ text }: { text: string }) => {
  const [localText, setLocalText] = useState<string>();
  useEffect(() => {
    setLocalText(text);
  }, [text]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {localText}
    </motion.div>
  );
};

export default TextSwap;
