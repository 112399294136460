import { LineType } from "../types/LineType";

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const generateLines = (count: number) => {
  let lineSet: LineType[] = [];
  for (let index = 0; index < count; index++) {
    lineSet.push({
      x: Math.random() < 0.5 ? "left" : "right",
      y: getRandomInt(5, 95) + "%",
      w: getRandomInt(5, 95) + "%",
      seed: Math.random(),
    });
  }
  return lineSet;
};

export { generateLines };
