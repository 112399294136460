import { Box, HStack, Text } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import { CgArrowLongRight } from "react-icons/cg";
import ParallaxMouse from "./ParallaxMouse";

const data = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Work",
    href: "/projects",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

const Nav = ({
  showNav,
  setShowNav,
}: {
  showNav: boolean;
  setShowNav: Dispatch<SetStateAction<boolean>>;
}) => {
  const [hoverTitle, setHoverTitle] = useState<string>("");
  return (
    <>
      <Box
        backgroundColor={"blackAlpha.400"}
        width={"100%"}
        height={"100%"}
        position={"fixed"}
        top={0}
        left={0}
        zIndex={6}
        opacity={showNav ? 1 : 0}
        pointerEvents={showNav ? "auto" : "none"}
        transition={"0.3s ease all"}
        onClick={() => {
          setShowNav(false);
        }}
      >
        <ParallaxMouse sensitivity={0.1}>
          <Text
            p={8}
            fontSize={{ base: "200px", md: "350px", lg: "500px" }}
            color="brand.main"
            transform={"skewY(5deg)"}
            fontWeight={"bold"}
            textTransform={"uppercase"}
            opacity={0.5}
          >
            {hoverTitle === "social" ? <CgArrowLongRight /> : hoverTitle}
          </Text>
        </ParallaxMouse>
      </Box>
      {/*  */}
      <Box
        position={"fixed"}
        bottom={"0"}
        right={"0"}
        height="50%"
        width="100%"
        borderRadius={"1000px"}
        background={"brand.main"}
        transform={"translateY(50%)"}
        filter={"blur(100px)"}
        pointerEvents={"none"}
        zIndex={6}
        opacity={showNav ? 0.5 : 0}
      ></Box>
      {/*  */}
      <Box
        backgroundColor={"blackAlpha.900"}
        position={"fixed"}
        bottom={0}
        right={0}
        width="100%"
        zIndex={7}
        p={{ base: 10, md: 12 }}
        opacity={showNav ? 1 : 0}
        transform={`translateY(${showNav ? "0%" : "100%"})`}
        pointerEvents={showNav ? "auto" : "none"}
        transition={"0.3s ease all"}
        alignItems={{ lg: "flex-end" }}
        color="white"
        display="flex"
      >
        <HStack
          spacing={6}
          flexDir={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          fontWeight={"bold"}
          textTransform={"uppercase"}
          flex={1}
        >
          {data.map((item) => {
            return (
              <Box
                key={item.label}
                fontSize={"2xl"}
                onMouseEnter={() => {
                  setHoverTitle(item.label);
                }}
                onMouseLeave={() => {
                  setHoverTitle("");
                }}
                _hover={{ color: "brand.main" }}
              >
                <Link
                  to={item.href}
                  onClick={() => {
                    setShowNav(false);
                  }}
                >
                  {item.label}
                </Link>
              </Box>
            );
          })}
        </HStack>
        <Box
          flex={0}
          textAlign={"right"}
          onMouseEnter={() => {
            setHoverTitle("social");
          }}
          onMouseLeave={() => {
            setHoverTitle("");
          }}
        >
          <Box
            whiteSpace={"nowrap"}
            transition={"0.3s ease all"}
            transform={"translateX(0)"}
            _hover={{ transform: "translateX(12px)", color: "brand.main" }}
          >
            <Link to="https://www.linkedin.com/in/vpfuentes/" target="_blank">
              LinkedIn <CgArrowLongRight style={{ display: "inline-block" }} />
            </Link>
          </Box>
          <Box
            whiteSpace={"nowrap"}
            transition={"0.3s ease all"}
            transform={"translateX(0)"}
            _hover={{ transform: "translateX(12px)", color: "brand.main" }}
          >
            <Link to="https://github.com/ftairs" target="_blank">
              Github <CgArrowLongRight style={{ display: "inline-block" }} />{" "}
            </Link>
          </Box>
          <Box
            whiteSpace={"nowrap"}
            transition={"0.3s ease all"}
            transform={"translateX(0)"}
            _hover={{ transform: "translateX(12px)", color: "brand.main" }}
          >
            <Link to="https://behance.net/ftairs" target="_blank">
              {" "}
              Behance <CgArrowLongRight
                style={{ display: "inline-block" }}
              />{" "}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          position={"fixed"}
          bottom={0}
          left={"33.33%"}
          height={showNav ? "100%" : "0%"}
          transition="0.3s ease all"
          width="1px"
          background={"white"}
          opacity={0.33}
          zIndex={6}
        ></Box>
        <Box
          position={"fixed"}
          top={0}
          right={"33.33%"}
          height={showNav ? "100%" : "0%"}
          transition="0.3s ease all"
          width="1px"
          background={"white"}
          opacity={0.33}
          zIndex={6}
        ></Box>
        <Box
          position={"fixed"}
          bottom={0}
          right={{ base: 10, md: 12 }}
          height={showNav ? "100%" : "0%"}
          transition="0.3s ease all"
          width="1px"
          background={"white"}
          opacity={0.33}
          zIndex={6}
        ></Box>
        <Box
          position={"fixed"}
          top={0}
          left={"50%"}
          height={showNav ? "100%" : "0%"}
          transition="0.3s ease all"
          width="1px"
          background={"white"}
          opacity={0.33}
          zIndex={6}
        ></Box>
      </Box>
    </>
  );
};

export default Nav;
