import { Box, Heading, Grid } from "@chakra-ui/react";
import textureImage from "../../assets/images/lines_texture.png";
import projects from "../../data/projectdata";
import ProjectCard from "../ProjectCard";
import ParallaxMouse from "../ParallaxMouse";
import "../../css/rotate.css";

const data = projects;

const Projects = () => {
  return (
    <Box
      color="white"
      paddingX={{ base: 10, md: 12 }}
      pt={60}
      pb={60}
      maxW={"100%"}
    >
      <Heading
        textAlign={"right"}
        fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
        mb={16}
        textTransform={"uppercase"}
      >
        Recent Projects
      </Heading>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
        gap={12}
        width={"100%"}
      >
        {data.map((item) => {
          return <ProjectCard align="left" project={item} />;
        })}
      </Grid>
      <Box
        position={"fixed"}
        top={"100px"}
        left={"50%"}
        transform={"translateX(-50%)"}
        pointerEvents={"none"}
      >
        <ParallaxMouse sensitivity={0.02}>
          <Box
            className="rotating-element--slow"
            backgroundImage={textureImage}
            backgroundSize={"auto 150%"}
            backgroundPosition={"center"}
            backgroundRepeat={"no-repeat"}
            opacity={0.25}
            borderRadius={"300px"}
            height="600px"
            width="600px"
          ></Box>
        </ParallaxMouse>
      </Box>
    </Box>
  );
};

export default Projects;
