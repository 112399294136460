import { Box, Heading, Flex, Text, Stack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_transparent.png";

const Footer = () => {
  return (
    <Box backgroundColor={"brand.main"} color="white" p={12}>
      <Stack spacing={4}>
        <Flex flexDir={{ base: "column", lg: "row" }} gap={{ base: 16, lg: 0 }}>
          <Stack flex={1} spacing={4}>
            <Box
              w={14}
              h={14}
              background={"brand.main"}
              borderRadius={"20px"}
              backgroundImage={logo}
              backgroundSize={"cover"}
              backgroundPosition={"center"}
            ></Box>
            <Box>
              <Heading>Victor Fuentes</Heading>
              <Text>Senior Front-End Engineer & Visual Designer</Text>
            </Box>
          </Stack>
          <Box>
            <Stack
              textTransform={"uppercase"}
              fontWeight={"bold"}
              textAlign={{ base: "left", md: "right" }}
            >
              <Box
                transform="translateX(0px)"
                transition={"0.3s ease all"}
                _hover={{
                  transform: "translateX(10px)",
                }}
              >
                <Link to="/">Home</Link>
              </Box>
              <Box
                transform="translateX(0px)"
                transition={"0.3s ease all"}
                _hover={{
                  transform: "translateX(10px)",
                }}
              >
                <Link to="/about">About</Link>
              </Box>
              <Box
                transform="translateX(0px)"
                transition={"0.3s ease all"}
                _hover={{
                  transform: "translateX(10px)",
                }}
              >
                <Link to="/projects">Projects</Link>
              </Box>
              <Box
                transform="translateX(0px)"
                transition={"0.3s ease all"}
                _hover={{
                  transform: "translateX(10px)",
                }}
              >
                <Link to="/contact">Contact</Link>
              </Box>
            </Stack>
          </Box>
        </Flex>
        <Box mt={16} h="1px" backgroundColor={"white"} fontSize="12px"></Box>
        <Flex>
          <Box flex={1}>&copy; 2024 - Designed & Hand Coded by Victor </Box>
        </Flex>
      </Stack>
    </Box>
  );
};

export default Footer;
