import { Box } from "@chakra-ui/react";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

type PageType = {
  children: React.ReactNode;
  title: string;
  desc?: string;
  backgroundColor?: string;
  showFooter?: boolean;
};

const Page = ({
  children,
  title,
  desc,
  backgroundColor,
  showFooter = true,
}: PageType) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <Box position="relative" overflow={"hidden"}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          style={{ minHeight: "100vh" }}
        >
          {children}
          {showFooter && <Footer />}
        </motion.div>
      </Box>
    </>
  );
};

export default Page;
