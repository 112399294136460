import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";

import ContactForm from "../ContactForm";
const links = [
  { label: "LinkedIn", href: "https://www.linkedin.com/in/vpfuentes/" },
  { label: "Github", href: "https://github.com/ftairs" },
  { label: "Email", href: "mailto:hello@victorpfuentes.com" },
  { label: "Behance", href: "https://behance.net/ftairs" },
];

const Contact = () => {
  return (
    <Box
      background="black"
      color="white"
      paddingTop={32}
      minHeight={"100vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      pos={"relative"}
      overflow={"hidden"}
    >
      <Flex
        alignItems={"center"}
        p={{ base: 10, md: 12 }}
        gap={16}
        flexDir={{ base: "column", md: "row" }}
        zIndex={5}
        position={"relative"}
      >
        <Box flex={1}>
          <Stack spacing={8} maxWidth={"600px"}>
            <Heading
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              color="brand.main"
            >
              Ready to talk?
            </Heading>
            <Text fontSize={{ base: "2xl", lg: "3xl" }}>
              <b>
                I'm currently seeking a new role as a Front-End Software
                Engineer
              </b>{" "}
              and would be delighted to talk about how I can bring value to your
              team.
            </Text>
            <Stack spacing={1}>
              {links.map((item) => {
                return (
                  <Box
                    key={item.label}
                    fontSize={"2xl"}
                    fontWeight={"bold"}
                    transform="translateX(0px)"
                    transition={"0.3s ease all"}
                    _hover={{
                      transform: "translateX(10px)",
                      color: "brand.main",
                    }}
                  >
                    <a href={item.href} target="_blank" rel="noreferrer">
                      {item.label}
                    </a>
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        </Box>
        <Box flex={1} minHeight="100px" width={{ base: "100%" }}>
          <Box
            background={"brand.trans"}
            color="black"
            p={{ base: 6, lg: 10 }}
            borderRadius={10}
            position={"relative"}
            zIndex={3}
            width={{ base: "100%" }}
          >
            <ContactForm />
          </Box>
        </Box>
      </Flex>
      <Box
        position={"absolute"}
        bottom={"0"}
        right={"0"}
        height="50%"
        width="100%"
        borderRadius={"1000px"}
        background={"brand.main"}
        transform={"translateY(50%)"}
        filter={"blur(100px)"}
        opacity={0.5}
        pointerEvents={"none"}
        zIndex={2}
      ></Box>
    </Box>
  );
};

export default Contact;
