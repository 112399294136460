import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  Flex,
  List,
  ListItem,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GiSpiderWeb } from "react-icons/gi";
import { TiSocialGithub } from "react-icons/ti";
import textureImage from "../assets/images/lines_texture.png";
import { ProjectType } from "../types/ProjectType";
import {
  TextContentType,
  ImageContentType,
  ListContentType,
  CtaContentType,
  ComingSoonContentType,
} from "../types/ContentTypes";

type ItemType =
  | TextContentType
  | ImageContentType
  | ListContentType
  | CtaContentType
  | ComingSoonContentType;

const MakeTemplate = ({
  item,
  currentProject,
}: {
  item: ItemType;
  currentProject: ProjectType;
}) => {
  // image
  if (item.type === "image") {
    return (
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"100%"}
      >
        <img
          src={item.src}
          alt=""
          style={{
            borderRadius: "20px",
            maxWidth: "100%",
          }}
        />
      </Box>
    );
  }
  // list
  if (item.type === "list") {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"600px"}
        p={8}
      >
        <Box border={""} padding={""}>
          {item.heading.length && (
            <Heading
              mb={10}
              fontSize={{ base: "md", md: "xl" }}
              textTransform={"uppercase"}
              letterSpacing={"2px"}
            >
              {item.heading}
            </Heading>
          )}
          {item.content?.length && (
            <List>
              {item.content.map((listItem: string) => {
                return (
                  <ListItem fontSize={{ base: "md", md: "2xl", lg: "4xl" }}>
                    <Box
                      w={4}
                      h={4}
                      background="brand.main"
                      display={"inline-block"}
                      mr={6}
                      borderRadius={8}
                    ></Box>
                    {listItem}
                  </ListItem>
                );
              })}
            </List>
          )}
        </Box>
      </Box>
    );
  }
  // text
  if (item.type === "text") {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"600px"}
        height={"100%"}
        p={8}
      >
        <Box border={""} padding={""} maxW={"100%"} width={"800px"}>
          {item.heading.length && (
            <Heading fontSize={{ base: "2xl", md: "6xl" }} mb={10}>
              {item.heading}
            </Heading>
          )}
          {item.content?.length && (
            <Text fontSize={{ base: "md", md: "2xl" }}>{item.content}</Text>
          )}
        </Box>
      </Box>
    );
  }
  // cta
  if (item.type === "cta") {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"400px"}
        p={8}
      >
        <Box border={"1px solid white"} padding={8}>
          {item.heading.length && (
            <Heading textAlign={"center"} mb={8} fontSize={"3xl"}>
              {item.heading}
            </Heading>
          )}
          <HStack
            gap={4}
            maxW={"100%"}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            {currentProject &&
              currentProject.link &&
              currentProject.link.length > 0 && (
                <Button variant="branded" as={Link} to={currentProject.link}>
                  Live Demo{" "}
                  <GiSpiderWeb
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "16px",
                    }}
                  />
                </Button>
              )}
            {currentProject &&
              currentProject.repoLink &&
              currentProject.repoLink.length > 0 && (
                <Button
                  variant="branded"
                  as={Link}
                  to={currentProject.repoLink}
                >
                  See On Github{" "}
                  <TiSocialGithub
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "16px",
                      fontSize: "40px",
                    }}
                  />
                </Button>
              )}
          </HStack>
        </Box>
      </Box>
    );
  }
  // coming soon
  if (item.type === "comingsoon") {
    return (
      <Box
        flex={1}
        p={8}
        textAlign={"center"}
        backgroundImage={textureImage}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        borderRadius={12}
        border="1px solid white"
      >
        <Box background={"black"} display={"inline-block"} p={10}>
          {item.heading.length && (
            <Heading fontSize={"xl"} mb={4}>
              {item.heading}
            </Heading>
          )}
          {item.content?.length && <Text fontSize={"4xl"}>{item.content}</Text>}
        </Box>
      </Box>
    );
  }

  return <></>;
};

const RenderContent = ({
  currentProject,
}: {
  currentProject: ProjectType | undefined;
}) => {
  return (
    <Flex alignItems={"center"} flexWrap={"wrap"} gap={4}>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={16} flex={1}>
        {currentProject &&
          typeof currentProject.content === "object" &&
          currentProject?.content.map((item: ItemType) => {
            return (
              <GridItem
                colSpan={{
                  base: 2,
                  lg:
                    item.fullWidth ||
                    item.type === "comingsoon" ||
                    item.type === "cta"
                      ? 2
                      : 1,
                }}
                mb={{ base: 10, md: 16, lg: 32 }}
              >
                <MakeTemplate currentProject={currentProject} item={item} />
              </GridItem>
            );
          })}
      </Grid>
    </Flex>
  );
};

export default RenderContent;
