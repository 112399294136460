import { extendTheme } from "@chakra-ui/react";

const theme: Record<string, string | {}> = extendTheme({
  colors: {
    black: "rgb(23, 24, 33)",
    white: "#f3feff",
    whiteAlpha: {
      "100": "rgba(243, 254, 255,0.1)",
      "200": "rgba(243, 254, 255,0.2)",
      "300": "rgba(243, 254, 255,0.3)",
      "400": "rgba(243, 254, 255,0.4)",
      "500": "rgba(243, 254, 255,0.5)",
      "600": "rgba(243, 254, 255,0.6)",
      "700": "rgba(243, 254, 255,0.7)",
      "800": "rgba(243, 254, 255,0.8)",
      "900": "rgba(243, 254, 255,0.9)",
    },
    blackAlpha: {
      "100": "rgba(23, 24, 33,0.1)",
      "200": "rgba(23, 24, 33,0.2)",
      "300": "rgba(23, 24, 33,0.3)",
      "400": "rgba(23, 24, 33,0.4)",
      "500": "rgba(23, 24, 33,0.5)",
      "600": "rgba(23, 24, 33,0.6)",
      "700": "rgba(23, 24, 33,0.7)",
      "800": "rgba(23, 24, 33,0.8)",
      "900": "rgba(23, 24, 33,0.9)",
    },
    brand: {
      main: "#0d2cc9",
      trans: "rgba(43, 38, 191, 0.1)",
    },
  },
  fonts: {
    heading: `'Ezra', sans-serif`,
    body: `'Ezra', sans-serif`,
  },
  styles: {
    global: () => ({
      body: {
        bg: "rgb(23, 24, 33)",
        color: "#f3feff",
        lineHeight: "1.35",
      },
    }),
  },
  components: {
    Button: {
      variants: {
        branded: {
          background: "rgba(43, 38, 191, 0.1)",
          border: "1px solid white",
          borderRadius: 8,
          paddingX: 10,
          paddingY: { base: 4, md: 8 },
          fontSize: { base: "12px", md: "18px" },
          letterSpacing: "2px",
          textTransform: "uppercase",
          position: "relative",
          overflow: "hidden",
          backdropFilter: "blur(4px)",
          zIndex: "1",
          "&:before": {
            content: '""',
            background: "white",
            position: "absolute",
            top: "100%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: "-1",
            borderColor: "white",
            transition: "0.3s ease all",
          },
          "&:hover": {
            color: "brand.main",
          },
          "&:hover:before": {
            top: "0",
          },
        },
        brandedInverted: {
          background: "transparent",
          border: "1px solid white",
          borderColor: "brand.main",
          borderRadius: 8,
          paddingX: 4,
          paddingY: 8,
          fontSize: "18px",
          letterSpacing: "2px",
          textTransform: "uppercase",
          position: "relative",
          overflow: "hidden",
          zIndex: "1",
          color: "brand.main",
          backdropFilter: "blur(4px)",
          "&:before": {
            content: '""',
            background: "brand.main",
            position: "absolute",
            top: "100%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: "-1",
            borderColor: "brand.main",
            transition: "0.3s ease all",
          },
          "&:hover": {
            color: "white",
          },
          "&:hover:before": {
            top: "0",
          },
        },
      },
    },
  },
});
export default theme;
