import { Box, Button, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
  name: string;
  email: string;
  message: string;
};

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await fetch(
        "http://www.thisisnameless.com/dev/send_email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data).toString(),
        }
      );

      if (response.ok) {
        alert("Your message has been sent successfully!");
      } else {
        alert("Failed to send the message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Heading
          paddingY={2}
          fontSize={"2xl"}
          textTransform={"uppercase"}
          fontWeight={"bold"}
          color={"brand.main"}
          textAlign={"center"}
        >
          Drop Me A Line...
        </Heading>
        <Box>
          <Text
            fontSize={"sm"}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            color="gray.500"
          >
            Name :
          </Text>
          <Input
            type="text"
            defaultValue=""
            {...register("name", { required: true })}
            color="white"
            borderRadius={"14px"}
            padding={6}
          />
          {errors.name && (
            <Box mt={2} fontWeight={"bold"} fontSize={"sm"} color="red">
              Name is required!
            </Box>
          )}
        </Box>
        <Box>
          <Text
            fontSize={"sm"}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            color="gray.500"
          >
            Email :
          </Text>
          <Input
            type="email"
            defaultValue=""
            {...register("email", { required: true })}
            color="white"
            borderRadius={"14px"}
            padding={6}
          />{" "}
          {errors.email && (
            <Box mt={2} fontWeight={"bold"} fontSize={"sm"} color="red">
              Email is required!
            </Box>
          )}
        </Box>
        <Box>
          <Text
            fontSize={"sm"}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            color="gray.500"
          >
            Message :
          </Text>
          <Input
            type="text"
            defaultValue=""
            {...register("message", { required: true })}
            color={"white"}
            borderRadius={"14px"}
            padding={6}
          />
          {errors.message && (
            <Box mt={2} fontWeight={"bold"} fontSize={"sm"} color="red">
              Message is required!
            </Box>
          )}
        </Box>
        <Box textAlign={"center"}>
          <Button variant="branded" color="white" type="submit" paddingX={10}>
            Submit
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ContactForm;
