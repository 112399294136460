import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CgArrowLongRight, CgArrowLongLeft } from "react-icons/cg";
import textureImage from "../../assets/images/lines_texture.png";
import projects from "../../data/projectdata";
import { useEffect } from "react";
import RenderContent from "../RenderContent";
import { ProjectsType, ProjectType } from "../../types/ProjectType";

function findPrevNextIds(projects: ProjectsType, selectedId: string) {
  const index = projects.findIndex(
    (project: ProjectType) => project.id === selectedId
  );
  if (index === -1) {
    return { prevId: null, nextId: null };
  }
  let prevId: string | null | undefined = null;
  let nextId: string | null | undefined = null;
  for (let i = index - 1; i >= 0; i--) {
    if (
      typeof projects[i].content === "object" &&
      projects[i].content !== null
    ) {
      prevId = projects[i].id;
      break;
    }
  }
  for (let i = index + 1; i < projects.length; i++) {
    if (
      typeof projects[i].content === "object" &&
      projects[i].content !== null
    ) {
      nextId = projects[i].id;
      break;
    }
  }
  return { prevId, nextId };
}

const SingleProject = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const currentProject = projects.find(
    (project: ProjectType) => project.id === projectId
  );
  const { prevId, nextId } = findPrevNextIds(
    projects,
    projectId ? projectId : ""
  );
  useEffect(() => {
    if (typeof currentProject?.content !== "object") {
      navigate("/projects");
    }
  }, [currentProject, navigate]);
  useEffect(() => {
    document.title = `${currentProject?.title} - Project - Victor Fuentes`;
  }, [currentProject, currentProject?.title]);
  return (
    <>
      <Box color="white">
        <Box backgroundColor={"brand.main"} opacity={0.8}>
          <Box
            pt={32}
            height={"500px"}
            backgroundImage={`url(${currentProject?.image})`}
            backgroundSize={"cover"}
            backgroundPosition={"center"}
            opacity={0.8}
          ></Box>
        </Box>
        <Box
          height={40}
          marginTop={-20}
          marginBottom={-20}
          backgroundImage={textureImage}
          backgroundPosition={"center center"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"120% auto"}
        ></Box>
        <Box>
          <Flex
            paddingX={{ base: 10, md: 10 }}
            paddingY={40}
            flexDir={{ base: "column", lg: "row" }}
            gap={{ base: 8, lg: 0 }}
          >
            <Box flex={1}>
              <Heading
                fontSize={{ base: "40px", md: "110px", lg: "140px" }}
                pr={20}
              >
                {currentProject !== undefined && currentProject.title}
              </Heading>
            </Box>
            <Stack flex={0.2}>
              <Box borderLeft={"1px solid white"} pl={8}>
                <Text fontSize={"xl"} fontWeight={"bold"} color="" mb={4}>
                  Date
                </Text>
                <Stack>
                  <Text fontSize={"6xl"} color="brand.main" fontWeight={"bold"}>
                    {currentProject !== undefined && currentProject.date}
                  </Text>
                </Stack>
              </Box>
            </Stack>
            <Stack flex={0.2}>
              <Box borderLeft={"1px solid white"} pl={8}>
                <Text fontSize={"xl"} fontWeight={"bold"} color="" mb={4}>
                  Skills & Tools
                </Text>
                <Stack>
                  {currentProject !== undefined &&
                    currentProject.tools &&
                    currentProject.tools.length > 0 &&
                    currentProject.tools.map((skill) => {
                      return (
                        <Text fontSize={"xl"} key={skill}>
                          {skill}
                        </Text>
                      );
                    })}
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Box>
        <Box padding={10} mb={8} background="black">
          <RenderContent currentProject={currentProject} />
        </Box>
        <Box paddingY={32}>
          <Flex alignItems={"center"} flexDir={{ base: "column", md: "row" }}>
            <Box flex={1} h={{ md: "1px" }} background="white"></Box>
            {prevId !== null && (
              <Link to={"/project/" + prevId}>
                <Flex
                  flex={1}
                  border={"1px solid white"}
                  color="white"
                  p={16}
                  alignItems={"center"}
                  pos={"relative"}
                  _hover={{
                    color: "brand.main",
                    cursor: "pointer",
                    ".css_navbuttonbacking": {
                      height: "100%",
                    },
                  }}
                >
                  <Box
                    className="css_navbuttonbacking"
                    background="white"
                    width="100%"
                    height="0"
                    position={"absolute"}
                    bottom={"0"}
                    left={"0"}
                    zIndex={1}
                    transition="0.3s ease all"
                  ></Box>
                  <Box
                    flex={0}
                    mr={8}
                    fontSize={"32px"}
                    pos={"relative"}
                    zIndex={2}
                  >
                    <CgArrowLongLeft />
                  </Box>
                  <Box flex={1} pos={"relative"} zIndex={2}>
                    <Text>
                      {projects.find((project) => prevId === project.id)?.title}
                    </Text>
                  </Box>
                </Flex>
              </Link>
            )}
            <Box
              flex={{ base: "auto", md: "0.2" }}
              minHeight={{ base: "100px", md: "1px" }}
              minWidth={{ base: "1px", md: "0px" }}
              background="white"
            ></Box>
            {nextId !== null && (
              <Link to={"/project/" + nextId}>
                <Flex
                  flex={1}
                  border={"1px solid white"}
                  color="white"
                  p={16}
                  alignItems={"center"}
                  pos={"relative"}
                  _hover={{
                    color: "brand.main",
                    cursor: "pointer",
                    ".css_navbuttonbacking": {
                      height: "100%",
                    },
                  }}
                >
                  <Box
                    className="css_navbuttonbacking"
                    background="white"
                    width="100%"
                    height="0"
                    position={"absolute"}
                    bottom={"0"}
                    left={"0"}
                    zIndex={1}
                    transition="0.3s ease all"
                  ></Box>
                  <Box flex={1} textAlign={"right"} pos={"relative"} zIndex={2}>
                    <Text>
                      {projects.find((project) => nextId === project.id)?.title}
                    </Text>
                  </Box>
                  <Box
                    flex={0}
                    ml={8}
                    fontSize={"32px"}
                    pos={"relative"}
                    zIndex={2}
                  >
                    <CgArrowLongRight />
                  </Box>
                </Flex>
              </Link>
            )}
            <Box flex={1} h={"1px"} background="white"></Box>
          </Flex>
        </Box>
      </Box>
      <Box>
        <Flex
          p={32}
          minHeight={"900px"}
          bgColor={"brand.main"}
          alignItems={"center"}
          justifyContent={"center"}
          color="white"
          minWidth={"100%"}
        >
          <Stack
            textAlign={"center"}
            spacing={16}
            minWidth={"100vw"}
            pos={"relative"}
          >
            <Text
              fontSize={{ base: "4xl", md: "5xl", lg: "7xl" }}
              fontWeight={"bold"}
              lineHeight={1.2}
              width="100%"
            >
              Interested in learning more?
            </Text>

            <Flex
              gap={8}
              flexDir={{ base: "column", lg: "row" }}
              alignItems={"center"}
              maxW={"900px"}
              w={"100%"}
              margin="0 auto"
            >
              <Box flex={1}>
                <Button
                  minW={"100%"}
                  as={Link}
                  to="/contact"
                  variant={"branded"}
                >
                  Contact Me
                </Button>
              </Box>
              <Box flex={1}>
                <Button minW={"100%"} as={Link} to="/about" variant={"branded"}>
                  About Me
                </Button>
              </Box>
            </Flex>
          </Stack>
        </Flex>
      </Box>
    </>
  );
};

export default SingleProject;
