import React, { useEffect, useState } from "react";

interface ParallaxMouseProps {
  children: React.ReactNode;
  sensitivity?: number;
}

const ParallaxMouse: React.FC<ParallaxMouseProps> = ({
  children,
  sensitivity = 0.05,
}) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      // Calculate the center of the viewport
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      // Calculate offset based on mouse position relative to the center
      const offsetX = (event.clientX - centerX) * sensitivity;
      const offsetY = (event.clientY - centerY) * sensitivity;

      setOffset({ x: offsetX, y: offsetY });
    };

    // Add event listener for mouse movement
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [sensitivity]);

  return (
    <div
      style={{
        transform: `translate(${offset.x}px, ${offset.y}px)`,
        transition: "transform 0.1s ease-out", // Smooth transition effect
        willChange: "transform",
      }}
    >
      {children}
    </div>
  );
};

export default ParallaxMouse;
