import { Box, HStack, Text } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  IoLogoCss3,
  IoLogoFigma,
  IoLogoHtml5,
  IoLogoJavascript,
  IoLogoNodejs,
  IoLogoReact,
} from "react-icons/io5";
import { SiAdobe } from "react-icons/si";
import { FaPhp } from "react-icons/fa6";
import TextSwap from "./TextSwap";

type LogoType = {
  icon: JSX.Element;
  color: string;
  label: string;
};

const logos: LogoType[] = [
  { icon: <IoLogoJavascript />, color: "#ffe138", label: "Javascript" },
  { icon: <IoLogoNodejs />, color: "#33ff1c", label: "Node.js" },
  { icon: <IoLogoReact />, color: "#1cceff", label: "React" },
  { icon: <IoLogoHtml5 />, color: "#3557f0", label: "HTML" },
  { icon: <IoLogoCss3 />, color: "#ff911c", label: "CSS" },
  { icon: <FaPhp />, color: "#787CB5", label: "PHP" },
  { icon: <SiAdobe />, color: "#ff331c", label: "Adobe Creative Suite" },
  { icon: <IoLogoFigma />, color: "#bf1cff", label: "Figma" },
];

type IconRenderProps = {
  logo: LogoType;
  setHoverLabel: Dispatch<SetStateAction<string>>;
  setTextPosition: Dispatch<SetStateAction<number>>;
  setDotColor: Dispatch<SetStateAction<string>>;
};

const IconRender: React.FC<IconRenderProps> = ({
  logo,
  setHoverLabel,
  setTextPosition,
  setDotColor,
}) => {
  const iconRef = useRef<HTMLDivElement | null>(null);

  const mouseEnterHandler = () => {
    if (iconRef.current) {
      const positionFromLeft = iconRef.current.offsetLeft;
      setTextPosition(positionFromLeft + iconRef.current.clientWidth / 2);
      setDotColor(logo.color);
    }
  };

  const mouseLeaveHandler = () => {
    setTextPosition(0);
    setDotColor("white");
  };

  return (
    <Box
      ref={iconRef}
      color={"gray.500"}
      transition={"0.3s ease all"}
      _hover={{ color: logo.color, transform: "scale(1.05)" }}
      aria-label={logo.label}
      fontSize={{ base: "60px", lg: "120px" }}
      onMouseEnter={() => {
        setHoverLabel(logo.label);
        mouseEnterHandler();
      }}
      onMouseLeave={() => {
        mouseLeaveHandler();
      }}
    >
      {logo.icon}
    </Box>
  );
};

const Techstack: React.FC = () => {
  const [hoverLabel, setHoverLabel] = useState<string>("");
  const [textPosition, setTextPosition] = useState<number>(0);
  const [dotColor, setDotColor] = useState<string>("");

  return (
    <Box textAlign={"center"}>
      <Box pos={"relative"}>
        <Box
          pos={"absolute"}
          bottom={"100%"}
          left={`${textPosition}px`}
          transition={"0.3s ease all"}
          textAlign={"center"}
          transform={"translateX(-50%)"}
          opacity={textPosition > 0 ? 1 : 0}
        >
          <Box>
            <Text fontSize={"2xl"} mb={2}>
              <TextSwap text={hoverLabel} />
            </Text>
            <Box
              w={4}
              h={4}
              mb={4}
              border={"4px solid white"}
              borderColor={dotColor}
              borderRadius={20}
              display={"inline-block"}
              transition={"0.3s ease all"}
            ></Box>
          </Box>
        </Box>
        <HStack
          spacing={{ base: 4, md: 8 }}
          position={"relative"}
          flexDir={{ base: "column", md: "row" }}
        >
          {logos.map((logo) => (
            <React.Fragment key={logo.label}>
              <IconRender
                logo={logo}
                setHoverLabel={setHoverLabel}
                setTextPosition={setTextPosition}
                setDotColor={setDotColor}
              />
            </React.Fragment>
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default Techstack;
