import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import textureImage from "../../assets/images/lines_texture.png";
import { CgArrowLongRight } from "react-icons/cg";
import ParallaxMouse from "../ParallaxMouse";

const Home = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <Box
      color="white"
      p={12}
      display={{ lg: "flex" }}
      alignItems={"center"}
      sx={{
        minHeight: "100vh",
      }}
      overflow={"hidden"}
    >
      <Heading
        fontSize={{
          base: "44px",
          sm: "110px",
          lg: "130px",
          xl: "170px",
        }}
        textTransform={"uppercase"}
        lineHeight={"1"}
        paddingY={20}
      >
        <ParallaxMouse sensitivity={0.01}>
          <>
            Senior Front End Engineer <br />
            <Box
              sx={{
                fontSize: { base: "26px", md: "30px", lg: "50px", xl: "80px" },
                display: "inline-block",
                verticalAlign: "text-top",
                color: "brand.main",
                position: "relative",
                top: { base: "3px", md: "6px", lg: "8px", xl: "10px" },
                marginRight: "20px",
                marginLeft: "4px",
              }}
              bgClip="text"
            >
              &
            </Box>
            Visual <br /> Designer
          </>
        </ParallaxMouse>
      </Heading>
      {/* side bit */}
      <Box
        position={{ lg: "fixed" }}
        bottom={10}
        right={{ base: 0, lg: 2 }}
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        gap={8}
        alignItems={"flex-end"}
        maxWidth={{ lg: "calc(33% + 40px)" }}
        padding={{ base: 0, lg: 10 }}
      >
        <Box>
          <Text
            p={0}
            pb={8}
            fontSize={{ base: "xl", md: "2xl" }}
            lineHeight={"1.2"}
          >
            I'm Victor Fuentes. I create things made of code and pixels. I've
            been working on the web, graphics and media for over 11 years.
          </Text>
          <Button variant={"branded"} w={"100%"} as={Link} to="/projects">
            See my work{" "}
            <CgArrowLongRight
              style={{
                marginLeft: "16px",
                display: "inline-block",
                fontSize: "40px",
              }}
            />{" "}
          </Button>
        </Box>
        <Box
          minWidth={{ base: "100%", lg: "80px" }}
          height={{ base: "100px", lg: "300px" }}
          backgroundImage={textureImage}
          backgroundSize={{ base: "120% auto", lg: "auto 120%" }}
          backgroundPosition={"center"}
          backgroundRepeat="no-repeat"
          borderRadius={8}
        ></Box>
      </Box>
      {/* lines */}
      <Box
        position={"absolute"}
        bottom={0}
        right={"calc(33% + 10px)"}
        marginRight={10}
        height={isLoaded ? "100%" : "0%"}
        transition={"2s ease all"}
        width="1px"
        background={"white"}
        opacity={0.33}
      ></Box>
      <Box
        position={"absolute"}
        top={0}
        left={12}
        height={isLoaded ? "100%" : "0%"}
        transition={"1s ease all"}
        width="1px"
        background={"white"}
        opacity={0.33}
      ></Box>
      <Box
        position={"absolute"}
        bottom={"-100px"}
        left={"-40px"}
        pointerEvents={"none"}
      >
        <ParallaxMouse sensitivity={0.3}>
          <Box
            height="200px"
            width="200px"
            background={"rgba(255,255,255,0.5)"}
            filter={"blur(100px)"}
          ></Box>
        </ParallaxMouse>
      </Box>
      <Box
        position={"absolute"}
        top={"-400px"}
        right={"10%"}
        pointerEvents={"none"}
      >
        <ParallaxMouse sensitivity={0.2}>
          <Box
            height="600px"
            width="600px"
            background={"brand.main"}
            filter={"blur(100px)"}
            opacity={0.5}
          ></Box>
        </ParallaxMouse>
      </Box>
    </Box>
  );
};

export default Home;
