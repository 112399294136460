import projectImageCssBlur from "../assets/images/projects/css_blur.jpg";
import projectImageSimpleReader from "../assets/images/projects/simple_reader.png";
import bescrapeImage from "../assets/images/projects/bescrape.png";
import fireflysImage from "../assets/images/projects/fireflys.png";
import eternalStaticImage from "../assets/images/projects/eternal_static.png";
import personalBrandImage from "../assets/images/projects/personal_brand.png";
import storeBrandImage from "../assets/images/projects/store_brand.png";
import linePortImages from "../assets/images/projects/portraits.png";
import lookingGlassImage from "../assets/images/projects/lookingglass.png";
import travelCardsImage from "../assets/images/projects/3dcards.png";
import cyclerImage from "../assets/images/projects/cycler.png";
import inputImage from "../assets/images/projects/inputeffect.png";
import lifecalcImage from "../assets/images/projects/lifecalc.png";
import { ProjectsType } from "../types/ProjectType";

// simple reader image
import simpleReader01 from "../assets/images/simple_reader/simple_reader_1.png";
import simpleReader03 from "../assets/images/simple_reader/simple_reader_3.png";
import simpleReader07 from "../assets/images/simple_reader/simple_reader_7.png";
import simpleReader08 from "../assets/images/simple_reader/simple_reader_8.png";
import simpleReader09 from "../assets/images/simple_reader/simple_reader_9.png";
import simpleReader10 from "../assets/images/simple_reader/simple_reader_10.png";

import vpfbrand01 from "../assets/images/vpf_brand/vpf_brand1.jpg";
import vpfbrand02 from "../assets/images/vpf_brand/vpf_brand2.jpg";
import vpfbrand03 from "../assets/images/vpf_brand/vpf_brand3.jpg";
import vpfbrand04 from "../assets/images/vpf_brand/vpf_brand4.jpg";
import vpfbrand05 from "../assets/images/vpf_brand/vpf_brand5.jpg";
import vpfbrand06 from "../assets/images/vpf_brand/vpf_brand6.jpg";

import abst01 from "../assets/images/abstryct/abst1.jpg";
import abst02 from "../assets/images/abstryct/abst2.jpg";
import abst03 from "../assets/images/abstryct/abst3.jpg";
import abst04 from "../assets/images/abstryct/abst4.jpg";

const projects: ProjectsType = [
  {
    id: "698d7efe-03c4-4e26-92f3-90bd15324578",
    title: "Simple Reader",
    type: "Web App",
    image: projectImageSimpleReader,
    link: "https://simple-reader.netlify.com",
    repoLink: "https://github.com/ftairs/simple-reader",
    tools: ["React", "ChakraUI", "Zustand", "Node.js"],
    desc: "Simple Reader is a demo project that combines data scraping, data compiling and a viewer for that data.",
    date: "2024",
    content: [
      {
        type: "text",
        heading: "Read On, Read Freely",
        content:
          "Simple Reader is a simple, data-based reader app that I created to read my favorite horror classics. Using freely available material from Project Gutenberg, it uses ethically scraped data. With some basec controls, a paged table-of-contents, and a responsive reader it hits all the points you would expect with a web-based reader. ",
        fullWidth: true,
      },
      {
        type: "image",
        src: simpleReader08,
        alt: "logo",
      },
      {
        type: "image",
        src: simpleReader07,
        alt: "diagram A",
      },
      {
        type: "image",
        src: simpleReader09,
        alt: "diagram B",
      },
      {
        type: "text",
        heading: "Project Goals",
        content:
          "There were two main goals for this project, for the back-end and the front-end. On the back-end I needed to successfully gather, compile and format the data for use within the app. On the front-end, I needed to display the content in a fun way while allowing for navigation and a bit of user controls. The project is created in a way that it could be repurposed for any publically available books that can be scraped and formatted correctly.",
      },
      {
        type: "list",
        heading: "Key Tools:",
        content: [
          "Node.js for the Back-End",
          "React.js for the Front-End",
          "Zustand for State Management",
        ],
      },
      {
        type: "image",
        src: simpleReader10,
        alt: "controls",
      },
      {
        type: "image",
        src: simpleReader03,
        alt: "Navigation",
        fullWidth: true,
      },
      {
        type: "text",
        heading: "Future Plans",
        content:
          "While this project hits the original marks I had, there is more I would like to do. Version 2 will end up probably redesigned, revamped and faster. Most noteably, the only feature I really felt was lacking while wrapping up was some kind of automated scrolling. That way people could read the text while it very slowly scrolled down.",
        fullWidth: true,
      },
      {
        type: "image",
        src: simpleReader01,
        alt: "full width view",
        fullWidth: true,
      },
      {
        type: "cta",
        heading: "Find Out More",
      },
    ],
  },
  {
    id: "336d578f-960a-47ba-b902-a4bd30f40821",
    title: "Life Calculator Experiment",
    type: "App Experiment",
    image: lifecalcImage,
    link: "https://cjjxsx-5173.csb.app/",
    repoLink: "",
    tools: [
      "Javascript",
      "React",
      "ChakraUI",
      "CSS",
      "UI Design",
      "Data Visualization",
    ],
    desc: "This project is part code, part art. Allowing for a sobering look at how much of life has passed.",
    content: "",
    date: "2024",
  },
  {
    id: "bbb85cf5-d3cf-4328-97dd-e4fc2fdb2fe9",
    title: "Glassmorphism Background with CSS",
    type: "UI Experiment",
    image: projectImageCssBlur,
    link: "",
    repoLink: "https://codepen.io/vector8/full/LYawxNg",
    tools: ["CSS", "UI Design"],
    desc: "A UI experiement exploring how to create glassmoorphism with just positioning, overflow and filters.",
    content: "",
    date: "2024",
  },
  {
    id: "a91b40e3-f6e3-4ad9-ba53-740240b82be3",
    title: "Vanilla JS Text Cycler",
    type: "UI Experiment",
    image: cyclerImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/vYMmpjw",
    tools: ["Javascript", "CSS", "Visual Design", "UI Design"],
    desc: "A vanilla JS text cycler that could use any number of words using a text array.",
    content: "",
    date: "2024",
  },
  {
    id: "f8bbf009-bb1f-4888-aa50-d08dc0bea5f2",
    title: "Text Input Hover Effect",
    type: "UI Experiment",
    image: inputImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/ZEZpjxr",
    tools: ["Javascript", "CSS", "Interaction Effects", "UI Design"],
    desc: "A interaction effect that adds some fun to text inputs.",
    content: "",
    date: "2024",
  },
  {
    id: "29215915-44af-40b8-9601-0a80681aa67e",
    title: "Fireflies",
    type: "Code Graphics Experiment",
    image: fireflysImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/ZEMVbeL",
    tools: ["Javascript", "Canvas API"],
    desc: "Take a moment to enjoy the peaceful view.",
    content: "",
    date: "2023",
  },
  {
    id: "2f5489b9-dd18-47ce-98be-ef8c455f4617",
    title: "Eternal static",
    type: "Code Graphics Experiment",
    image: eternalStaticImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/NWMZxrL",
    tools: ["Javascript", "Canvas API"],
    desc: "Randomly generated graphic that is half code and half art. Never the same twice.",
    content: "",
    date: "2023",
  },
  {
    id: "a4cd9b7c-3a7a-458a-b001-fc5642515592",
    title: "Personal Brand Identity",
    type: "Brand Identity Design",
    image: personalBrandImage,
    link: "https://www.behance.net/gallery/193173865/Personal-Brand-Refresh-2024",
    repoLink: "",
    tools: ["Identity Design", "Graphic Design", "Illustrator"],
    desc: "My personal brand for myself. Created in late 2023 and revamped in early 2024.",
    date: "2024",
    content: [
      {
        type: "text",
        heading: "Ready For 2025",
        content:
          "Going into 2025, I feel many brands are returning to full featured and lush visual identities. I've created a new tech-centric personal brand identity that showcases my creativity, enginuity and attention to detail. My goal was to take from my older inspiration while looking ahead to deliver something that felt next-level.",
      },
      {
        type: "image",
        src: vpfbrand06,
        alt: "dark on light",
      },
      {
        type: "image",
        src: vpfbrand03,
        alt: "logo - 3 ways",
        fullWidth: true,
      },
      {
        type: "image",
        src: vpfbrand04,
        alt: "zoomed",
      },
      {
        type: "list",
        heading: "Visual Keywords",
        content: ["Modern", "Unique", "Dark", "Flowing", "Bold"],
      },
      {
        type: "image",
        src: vpfbrand01,
        alt: "texture",
        fullWidth: true,
      },
      {
        type: "list",
        heading: "Identity Assets:",
        content: [
          "Responsive Logo",
          "Color Selection",
          "Font Selections",
          "Textures",
          "Motifs",
        ],
      },
      {
        type: "image",
        src: vpfbrand05,
        alt: "",
      },
      {
        type: "image",
        src: vpfbrand02,
        alt: "",
        fullWidth: true,
      },
      {
        type: "cta",
        heading: "Find Out More",
      },
    ],
  },
  {
    id: "39ea8182-8382-4944-a26f-7e00de9898aa",
    title: "Monoline Portraits 2024",
    type: "Illustration",
    image: linePortImages,
    link: "https://www.behance.net/gallery/193162227/Monoline-Portraits-2024?",
    repoLink: "",
    tools: ["Illustration", "Procreate", "Visual Design"],
    desc: "I regulary make and release these as a for-fun project. Created on Procreate on iPad. A pure labor of fun, art and simplicity.",
    content: "",
    date: "2024",
  },
  {
    id: "15733b14-9322-4312-bd92-d49f2d17f289",
    title: "Abstryct Apparel Logo",
    type: "Brand Identity Design",
    image: storeBrandImage,
    link: "https://www.behance.net/gallery/193173357/Brand-Identity-ABSTRYCT",
    repoLink: "",
    tools: ["Identity Design", "Graphic Design", "Illustrator"],
    desc: "A brand identity for an upcoming webstore featuring geometric designs ranging from a few simple lines to complex, visually lush linework.",
    content: [
      {
        type: "text",
        heading: "A Mystic Adventure into a World of Geometric Lineart",
        content:
          " Abstryct is a project that have been culminating throughout 2024. The spirit of the brand is one of simple beauty. Found in the logical layout of lines and shapes. What does it all mean? Is it all random? We will find out in early 2025.",
      },
      {
        type: "image",
        src: abst01,
        alt: "logo",
      },
      {
        type: "image",
        src: abst02,
        alt: "dark",
      },
      {
        type: "image",
        src: abst03,
        alt: "range",
      },
      {
        type: "image",
        src: abst04,
        alt: "light",
        fullWidth: true,
      },
      {
        type: "comingsoon",
        heading: "Store Opening Soon",
        content: "Early 2025",
      },
    ],
    date: "2024",
  },
  {
    id: "2ff0ba0e-fe18-40d1-82e9-1ed8ca1b877f",
    title: "Looking Glass Experiment",
    type: "UI Experiment",
    image: lookingGlassImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/bGJVEMd",
    tools: ["Javascript", "CSS", "Interaction", "UI Design"],
    desc: "A UI feature that allows a use to reveal an image using their mouse position.",
    content: "",
    date: "2023",
  },
  {
    id: "15fe975d-ec71-4a09-bed8-8cf18ca9fadd",
    title: "3D Hover Cards",
    type: "UI Experiment",
    image: travelCardsImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/rNoEvyL",
    tools: ["Javascript", "CSS", "Interaction", "UI Design"],
    desc: "A simple 3D effect on hovering of cards. Tunable to have more or less 3d rotation.",
    content: "",
    date: "2024",
  },
  {
    id: "01020b93-5aa5-4357-b2b5-dc077765e253",
    title: "BeScrape",
    type: "Web Scraper App",
    image: bescrapeImage,
    link: "",
    repoLink: "",
    tools: [],
    desc: "BeScrape is a scraper app that gets data from Behance projects, their metadata and content so that you can extract and save that data. Being remade as a public tool now.",
    wip: true,
    content: "",
    date: "2024",
  },
];

export default projects;
