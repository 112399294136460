import {
  Box,
  Heading,
  Button,
  HStack,
  Stack,
  Text,
  useToken,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { FaCodepen } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { GiSpiderWeb } from "react-icons/gi";
import { MdReadMore } from "react-icons/md";
import { Link } from "react-router-dom";
import { ProjectType } from "../types/ProjectType";

const ProjectCard = ({
  project,
  align,
}: {
  project: ProjectType;
  align: "right" | "left";
}) => {
  const [brandColor] = useToken("colors", ["brand.main"]);
  const [beingHovered, setBeingHovered] = useState<boolean>(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      backgroundColor="black"
      position="relative"
      border="1px solid white"
      borderRadius={8}
      overflow="hidden"
      textAlign={"center"}
      paddingX={8}
      paddingY={"100px"}
      width="100%"
      height="100%"
      boxShadow={{ base: `0 0 0 ${brandColor}` }}
      transition="0.3s ease all"
      _hover={{
        boxShadow: `10px 10px 0 ${brandColor}`,
        "&:hover .css-gradient": {
          opacity: 0.6,
        },
      }}
      onMouseEnter={() => setBeingHovered(true)}
      onMouseLeave={() => setBeingHovered(false)}
    >
      {/* Background Image */}
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        backgroundImage={`url(${project.image})`}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius={6}
        zIndex={1}
        transition="0.3s ease all"
        opacity={beingHovered ? 1 : 0}
      ></Box>

      {/* Gradient Overlay */}
      <Box
        className="css-gradient"
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        backgroundColor="brand.main"
        bgGradient="linear(to-br, brand.main, black)"
        transition="0.3s ease all"
        opacity={0.33}
        borderRadius={6}
        zIndex={1}
      ></Box>

      {/* Project Date */}
      <Box
        position="absolute"
        top={4}
        right={4}
        borderRadius={4}
        display="flex"
        alignItems="center"
        color="brand.main"
        transition="0.3s ease all"
        transformOrigin="top right"
        transform={{ base: "scale(0.5)", md: "scale(1)" }}
        zIndex={10}
      >
        <Text fontSize="xl" mr={-10} color="white" opacity="0.33">
          {project.date}
        </Text>
        <CgArrowLongRight
          style={{
            display: "inline-block",
            fontSize: "100px",
            opacity: 0.33,
          }}
        />
      </Box>

      {/* Project Content */}
      <Stack flex={1} spacing={2} paddingY={8} position="relative" zIndex={2}>
        <Stack spacing={{ base: 4, md: 2 }}>
          <Heading fontSize={{ base: "2xl", md: "5xl", lg: "6xl" }}>
            {project.title}
          </Heading>
          <Box marginBottom={8}>
            <Text fontSize={{ base: "sm", md: "xl" }} fontWeight="bold" mb={4}>
              {project.type}
            </Text>
            {project.tools && project.tools.length > 0 && (
              <Box>
                <Text
                  color="brand.main"
                  fontSize="12px"
                  paddingX={3}
                  paddingY={1}
                  borderRadius={8}
                  background={"whiteAlpha.800"}
                  display="inline-block"
                >
                  {project.tools.join(" // ")}
                </Text>
              </Box>
            )}
          </Box>
          {project.wip && (
            <Box>
              <Button
                variant="branded"
                display="inline-flex"
                color="white"
                alignItems="center"
                disabled
                background="brand.main"
              >
                In Progress
                <Box display={{ base: "none", md: "inline-block" }}>
                  <Spinner
                    thickness="4px"
                    speed="4s"
                    emptyColor="blackAlpha.500"
                    color="white"
                    size="md"
                    ml={4}
                  />
                </Box>
              </Button>
            </Box>
          )}
          <HStack spacing={8} flexDir={{ base: "column" }}>
            {project.link && (
              <Button
                variant="branded"
                as={Link}
                to={project.link}
                target="_blank"
              >
                Live Demo
                <Box display={{ base: "none", md: "inline-block" }}>
                  <GiSpiderWeb
                    style={{ verticalAlign: "middle", marginLeft: "16px" }}
                  />
                </Box>
              </Button>
            )}
            {project.repoLink && (
              <Button
                variant="branded"
                as={Link}
                to={project.repoLink}
                target="_blank"
              >
                {project.repoLink.toLowerCase().includes("codepen")
                  ? "Codepen"
                  : "Github"}
                <Box display={{ base: "none", md: "inline-block" }}>
                  {project.repoLink.toLowerCase().includes("codepen") ? (
                    <FaCodepen
                      style={{ verticalAlign: "middle", marginLeft: "16px" }}
                    />
                  ) : (
                    <FaGithub
                      style={{ verticalAlign: "middle", marginLeft: "16px" }}
                    />
                  )}
                </Box>
              </Button>
            )}
            {project.content && (
              <Button variant="branded" as={Link} to={`/project/${project.id}`}>
                Read More
                <Box display={{ base: "none", md: "inline-block" }}>
                  <MdReadMore
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "16px",
                      fontSize: "40px",
                    }}
                  />
                </Box>
              </Button>
            )}
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProjectCard;
