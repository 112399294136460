import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Nav from "./Nav";

import logo from "../assets/images/logo_transparent.png";
import { GrNodes } from "react-icons/gr";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <>
      <Box
        pos={"fixed"}
        top={0}
        left={0}
        width={"100%"}
        color={"white"}
        zIndex={5}
      >
        <Flex p={{ base: 10, md: 12 }} alignItems={"center"} gap={6}>
          <Box flex={0}>
            <Link to="/">
              <motion.div
                animate={{
                  scale: [1, 1.15, 1],
                }}
                transition={{
                  duration: 5,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatDelay: 0,
                }}
              >
                <Box
                  w={14}
                  h={14}
                  background={"brand.main"}
                  borderRadius={"100px"}
                  backgroundImage={logo}
                  backgroundSize={"cover"}
                  backgroundPosition={"center"}
                  transform={`rotate(${isLoaded ? "0" : "200"}deg)`}
                  transition={"0.5s ease all"}
                ></Box>
              </motion.div>
            </Link>
          </Box>
          <Box flex={0} display={{ base: "none", lg: "block" }}>
            <Box
              w={{ base: "50px", lg: "100px" }}
              h={"4px"}
              background={"white"}
            ></Box>
          </Box>
          <Box flex={0} display={{ base: "none", lg: "block" }}>
            <Heading
              fontSize={"xl"}
              textTransform={"uppercase"}
              whiteSpace={"nowrap"}
            >
              Victor Fuentes
            </Heading>
          </Box>
          <Box flex={1}></Box>
          <Box flex={0}>
            <Box
              w={14}
              h={14}
              background={"brand.main"}
              borderRadius={"100px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize={32}
              transition={"0.5s ease all"}
              transform={showNav ? `rotate(${220}deg)` : `rotate(${0}deg)`}
              cursor={"pointer"}
              onClick={() => {
                setShowNav(true);
              }}
            >
              <GrNodes />
            </Box>
          </Box>
        </Flex>
      </Box>
      <Nav showNav={showNav} setShowNav={setShowNav}></Nav>
    </>
  );
};

export default Header;
